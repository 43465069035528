import { CardMedia, Collapse, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useNavSection } from "./hooks/useNavSection";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";

const useStyles = makeStyles((theme) => ({
  navLink: {
    textDecoration: "none",
  },
}));
const NavSection = () => {
  const { shiftManagementCollapse, setShiftManagementCollapse } =
    useNavSection();
  const classes = useStyles();
  const location = useLocation();

  return (
    <Box sx={{ width: "100%", height: "100%", mb: 2, mt: 1 }}>
      {/* Dashboard */}
      <NavLink to="/dashboard" className={classes.navLink}>
        <Box
          className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
          sx={{
            boxShadow:
              location.pathname === "/dashboard"
                ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                : "",
            borderRadius: 2,
            mt: 2,
            backgroundColor: location.pathname === "/dashboard" ? "white" : "",
            "&:hover": {
              color: "white",
              backgroundColor: "RGB(225 222 229)",
            },
          }}
        >
          <Box
            className="dashboard-icon-shadow"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "30px",
              height: "30px",
              ml: "20px",
              backgroundColor:
                location.pathname === "/dashboard"
                  ? "RGB(124 140 170)"
                  : "white",
            }}
          >
            <CardMedia
              component="img"
              sx={{ height: 15, width: 15 }}
              image={
                location.pathname === "/dashboard"
                  ? "../images/homeWhite.svg"
                  : "../images/home.svg"
              }
              alt="home"
            />
          </Box>
          <Typography
            sx={{
              color: "#67748E",
              fontStyle: "bold",
              ml: 1,
              lineHeight: "22px",
              fontSize: "14px",
              fontWeight: location.pathname === "/dashboard" ? "bold" : "",
            }}
          >
            Dashboard
          </Typography>
        </Box>
      </NavLink>

      <Grid>
        {/* Reports */}
        <Typography
          sx={{
            mt: 3,
            ml: 3,
            fontWeight: 700,
            fontSize: 12,
            fontFamily: "Open Sans",
            opacity: "60%",
            color: "rgba(52, 71, 103, 1)",
          }}
        >  
          REPORTS
        </Typography>

        {/* Transactions */}
        <NavLink
          to="/transaction"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/transaction"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor:
                location.pathname === "/transaction" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/transaction"
                    ? "RGB(124 140 170)"
                    : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/transaction"
                    ? "../images/blockWhite.svg"
                    : "../images/block.svg"
                }
                alt="fee"
              />
            </Box>
            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={location.pathname === "/transaction" ? "bold" : ""}
            >
              Transaction
            </Typography>
          </Box>
        </NavLink>

        {/* Cash Collection */}
        <NavLink
          to="/cashcollect"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/cashcollect"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor:
                location.pathname === "/cashcollect" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/cashcollect"
                    ? "RGB(124 140 170)"
                    : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/cashcollect"
                    ? "../images/blockWhite.svg"
                    : "../images/block.svg"
                }
                alt="fee"
              />
            </Box>
            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={location.pathname === "/cashcollect" ? "bold" : ""}
            >
              Collection
            </Typography>
          </Box>
        </NavLink>

        {/* Settlement Report */}
        <NavLink
          to="/settlement-report"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/settlement-report"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor:
                location.pathname === "/settlement-report" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/settlement-report"
                    ? "RGB(124 140 170)"
                    : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/settlement-report"
                    ? "../images/blockWhite.svg"
                    : "../images/block.svg"
                }
                alt="fee"
              />
            </Box>
            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={location.pathname === "/settlement-report" ? "bold" : ""}
            >
              Settlement
            </Typography>
          </Box>
        </NavLink>

        {/* complementary Report */}
        <NavLink
          to="/complimentary-details"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/complimentary-details"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor:
                location.pathname === "/complimentary-details" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/complimentary-details"
                    ? "RGB(124 140 170)"
                    : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/complimentary-details"
                    ? "../images/blockWhite.svg"
                    : "../images/block.svg"
                }
                alt="fee"
              />
            </Box>
            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={
                location.pathname === "/complimentary-details" ? "bold" : ""
              }
            >
              Compliments
            </Typography>
          </Box>
        </NavLink>

        {/* Attendence */}
        <NavLink
          to="/attendence"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/attendence"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor:
                location.pathname === "/attendence" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/attendence"
                    ? "RGB(124 140 170)"
                    : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/attendence"
                    ? "../images/blockWhite.svg"
                    : "../images/block.svg"
                }
                alt="fee"
              />
            </Box>
            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={location.pathname === "/attendence" ? "bold" : ""}
            >
              Attendance
            </Typography>
          </Box>
        </NavLink>

        {/* Collection */}

        {/* <NavLink
          to="/collections"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/collections"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor:
                location.pathname === "/collections" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
                borderRadius: 8,
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/collections"
                    ? "RGB(124 140 170)"
                    : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/collections"
                    ? "../images/blockWhite.svg"
                    : "../images/block.svg"
                }
                alt="fee"
              />
            </Box>
            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={location.pathname === "/collections" ? "bold" : ""}
            >
              Collection
            </Typography>
          </Box>
        </NavLink> */}


         {/* Parking Type */}
         <NavLink 
          to="/parking-type"
          className={classes.navLink}
          activeClassName={classes.activeLink}
          
         
         >
          <Box 
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
              location.pathname === "/parking-type"
              ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
              : "",
              borderRadius: 2,
              backgroundColor:
              location.pathname === "/parking-type" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },


            }}
          >
           <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/parking-type"
                    ? "RGB(124 140 170)"
                    : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/parking-type"
                    ? "../images/blockWhite.svg"
                    : "../images/block.svg"
                }
                alt="fee"
              />
            </Box>

            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={location.pathname === "/Parking-type" ? "bold" : ""}
            >
              Parking Type
            </Typography>
            
          </Box>

            
         </NavLink>

        {/* Masters */}
        <Typography
          sx={{
            mt: 2,
            ml: 3,
            fontWeight: 700,
            fontSize: 12,
            fontFamily: "Open Sans",
            opacity: "60%",
            color: "rgba(52, 71, 103, 1)",
          }}
        >
          MASTERS
        </Typography>

        {/* ShiftManagement */}
        <NavLink
          to="/shiftmaster"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            onClick={() => {
              setShiftManagementCollapse(!shiftManagementCollapse);
            }}
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/shiftmaster"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor:
                location.pathname === "/shiftmaster" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/shiftmaster"
                    ? "RGB(124 140 170)"
                    : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/shiftmaster"
                    ? "../images/slotWhite.svg"
                    : "../images/slot.svg"
                }
                alt="slot"
              />
            </Box>

            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={location.pathname === "/shiftmaster" ? "bold" : ""}
              // onClick={() => {
              //   setShiftManagementCollapse(!shiftManagementCollapse);
              // }}
            >
              Shift Management
            </Typography>
            <Box>
              {shiftManagementCollapse === true ? (
                <ExpandCircleDownOutlinedIcon
                  sx={{
                    fontSize: "13px",
                    ml: 1,
                    mt: 0.3,
                    transform: "rotate(180deg)",
                  }}
                />
              ) : (
                <ExpandCircleDownOutlinedIcon
                  sx={{
                    fontSize: "13px",
                    ml: 1,
                    mt: 0.3,
                  }}
                />
              )}
            </Box>
          </Box>
        </NavLink>

        <Collapse className="collapse-link" in={shiftManagementCollapse}>
          <Box className="ml-[18%] mt-3">
            <Box>
              <NavLink className="no-underline" to="/shiftmaster">
                <Typography
                  fontStyle="Open Sans"
                  className="text-[#67748E]"
                  fontWeight={
                    location.pathname === "/shiftmaster" ? "bold" : ""
                  }
                >
                  Shift
                </Typography>
              </NavLink>
            </Box>
          </Box>
        </Collapse>

        <Collapse className="collapse-link" in={shiftManagementCollapse}>
          <Box className="ml-[18%] mt-3">
            <Box>
              <NavLink className="no-underline" to="/shiftmaster/assign">
                <Typography
                  fontStyle="Open Sans"
                  className="text-[#67748E]"
                  fontWeight={
                    location.pathname === "/shiftmaster/assign" ? "bold" : ""
                  }
                >
                  Assign
                </Typography>
              </NavLink>
            </Box>
          </Box>
        </Collapse>

        {/* <Collapse className="collapse-link" in={shiftManagementCollapse}>
          <Box className="ml-[18%] mt-3">
            <Box>
              <NavLink className="no-underline" to="/shiftmaster/history">
                <Typography
                  fontStyle="Open Sans"
                  className="text-[#67748E]"
                  fontWeight={
                    location.pathname === "/shiftmaster/history" ? "bold" : ""
                  }
                >
                  History
                </Typography>
              </NavLink>
            </Box>
          </Box>
        </Collapse> */}

        {/* Staff Manangement */}
        <NavLink
          to="/staffonboarding"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/staffonboarding"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor:
                location.pathname === "/staffonboarding" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/staffonboarding"
                    ? "RGB(124 140 170)"
                    : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/staffonboarding"
                    ? "../images/slotWhite.svg"
                    : "../images/slot.svg"
                }
                alt="slot"
              />
            </Box>

            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={
                location.pathname === "/staffonboarding" ? "bold" : ""
              }
            >
              Staff Management
            </Typography>
          </Box>
        </NavLink>

        {/* Vehicle */}
        <NavLink
          to="/vehicle"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/vehicle"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor: location.pathname === "/vehicle" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/vehicle"
                    ? "RGB(124 140 170)"
                    : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/vehicle"
                    ? "../images/blockWhite.svg"
                    : "../images/block.svg"
                }
                alt="fee"
              />
            </Box>
            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={location.pathname === "/vehicle" ? "bold" : ""}
            >
              Vehicle
            </Typography>
          </Box>
        </NavLink>

        {/* Parking Type */}
        <NavLink
          to="/parkingtype"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/parkingtype"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor:
                location.pathname === "/parkingtype" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/parkingtype"
                    ? "RGB(124 140 170)"
                    : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/parkingtype"
                    ? "../images/blockWhite.svg"
                    : "../images/block.svg"
                }
                alt="fee"
              />
            </Box>
            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={location.pathname === "/parkingtype" ? "bold" : ""}
            >
              Parking Type
            </Typography>
          </Box>
        </NavLink>

        {/* Fee */}
        <NavLink
          to="/fee"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/fee"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor: location.pathname === "/fee" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/fee" ? "RGB(124 140 170)" : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/fee"
                    ? "../images/blockWhite.svg"
                    : "../images/block.svg"
                }
                alt="fee"
              />
            </Box>
            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={location.pathname === "/fee" ? "bold" : ""}
            >
              Fee
            </Typography>
          </Box>
        </NavLink>

        {/* Payment Mode  */}
        <NavLink
          to="/paymentmode"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/paymentmode"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor:
                location.pathname === "/paymentmode" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/paymentmode"
                    ? "RGB(124 140 170)"
                    : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/paymentmode"
                    ? "../images/floorWhite.svg"
                    : "../images/floor.svg"
                }
                alt="paymentmode"
              />
            </Box>

            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={location.pathname === "/paymentmode" ? "bold" : ""}
            >
              Payment Mode
            </Typography>
          </Box>
        </NavLink>

        {/* EntryBooth */}
        <NavLink
          to="/entrybooth"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/entrybooth"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor:
                location.pathname === "/entrybooth" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/entrybooth"
                    ? "RGB(124 140 170)"
                    : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/entrybooth"
                    ? "../images/slotWhite.svg"
                    : "../images/slot.svg"
                }
                alt="entrybooth"
              />
            </Box>

            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={location.pathname === "/entrybooth" ? "bold" : ""}
            >
              Gates
            </Typography>
          </Box>
        </NavLink>

        {/* ExitBooth */}
        {/* <NavLink
          to="/exitbooth"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/exitbooth"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor:
                location.pathname === "/exitbooth" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/exitbooth"
                    ? "RGB(124 140 170)"
                    : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/exitbooth"
                    ? "../images/slotWhite.svg"
                    : "../images/slot.svg"
                }
                alt="exitbooth"
              />
            </Box>

            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={location.pathname === "/exitbooth" ? "bold" : ""}
            >
              Exit Booth
            </Typography>
          </Box>
        </NavLink> */}

        {/* Loction */}
        <NavLink
          to="/location-master"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/location-master"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor:
                location.pathname === "/location-master" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/location-master"
                    ? "RGB(124 140 170)"
                    : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/location-master"
                    ? "../images/blockWhite.svg"
                    : "../images/block.svg"
                }
                alt="Live r"
              />
            </Box>
            <Typography
              component="div"
              ml="10px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={
                location.pathname === "/location-master" ? "bold" : ""
              }
            >
              Location
            </Typography>
          </Box>
        </NavLink>

        {/* Block */}
        <NavLink
          to="/block"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/block"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor: location.pathname === "/block" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/block" ? "RGB(124 140 170)" : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/block"
                    ? "../images/blockWhite.svg"
                    : "../images/block.svg"
                }
                alt="Live r"
              />
            </Box>
            <Typography
              component="div"
              ml="10px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={location.pathname === "/block" ? "bold" : ""}
            >
              Block
            </Typography>
          </Box>
        </NavLink>

        {/* Floor  */}
        <NavLink
          to="/floor"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/floor"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor: location.pathname === "/floor" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/floor" ? "RGB(124 140 170)" : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/floor"
                    ? "../images/floorWhite.svg"
                    : "../images/floor.svg"
                }
                alt="floor"
              />
            </Box>

            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={location.pathname === "/floor" ? "bold" : ""}
            >
              Floor
            </Typography>
          </Box>
        </NavLink>

        {/* Slot */}
        <NavLink
          to="/slot"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/slot"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor: location.pathname === "/slot" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/slot" ? "RGB(124 140 170)" : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/slot"
                    ? "../images/slotWhite.svg"
                    : "../images/slot.svg"
                }
                alt="slot"
              />
            </Box>

            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={location.pathname === "/slot" ? "bold" : ""}
            >
              Slot
            </Typography>
          </Box>
        </NavLink>

        <NavLink
          to="/extra-charges"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/extra-charges"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor:
                location.pathname === "/extra-charges" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/extra-charges"
                    ? "RGB(124 140 170)"
                    : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/extra-charges"
                    ? "../images/slotWhite.svg"
                    : "../images/slot.svg"
                }
                alt="slot"
              />
            </Box>

            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={location.pathname === "/extra-charges" ? "bold" : ""}
            >
              Extra Charges
            </Typography>
          </Box>
        </NavLink>

        <Divider
          variant="middle"
          className="bg-gradient-to-r from-[rgba(0, 0, 0, 0.4)] to-[rgba(0, 0, 0, 0)] h-[1px]"
        />

        <Typography
          sx={{
            mt: 2,
            ml: 3,
            fontWeight: 700,
            fontSize: 12,
            fontFamily: "Open Sans",
            opacity: "60%",
            color: "rgba(52, 71, 103, 1)",
          }}
        >
          SETTINGS
        </Typography>

        <NavLink
          to="/profile-settings"
          className={classes.navLink}
          activeClassName={classes.activeLink}
        >
          <Box
            className="flex items-center m-auto mt-1 w-[13.5rem] h-[3.3rem]"
            sx={{
              boxShadow:
                location.pathname === "/profile-settings"
                  ? "0px 11px 21px 0px rgba(0,0,0,0.1)"
                  : "",
              borderRadius: 2,
              backgroundColor:
                location.pathname === "/profile-settings" ? "white" : "",
              "&:hover": {
                color: "white",
                backgroundColor: "RGB(225 222 229)",
              },
            }}
          >
            <Box
              className="dashboard-icon-shadow"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                ml: "20px",
                backgroundColor:
                  location.pathname === "/profile-settings"
                    ? "RGB(124 140 170)"
                    : "white",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: 15, width: 15 }}
                image={
                  location.pathname === "/profile-settings"
                    ? "../images/slotWhite.svg"
                    : "../images/slot.svg"
                }
                alt="slot"
              />
            </Box>

            <Typography
              component="div"
              ml="10px"
              lineHeight="22px"
              fontStyle="Open Sans"
              color="#67748E"
              fontSize="14px"
              fontWeight={
                location.pathname === "/profile-settings" ? "bold" : ""
              }
            >
              Profile settings
            </Typography>
          </Box>
        </NavLink>
      </Grid>
    </Box>
  );
};

export default NavSection;
