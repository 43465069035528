import React from "react";
import { Route, Routes as RouterRoutes, useLocation } from "react-router-dom";
import UserHeader from "../containers/components/Header/UserHeader";
import EntryDetailsPage from "../features/entry-exit/pages/Entry/EntryDetailsPage";
import ExitDetailsPage from "../features/entry-exit/pages/Exit/ExitDetailsPage";
import GateSelectionPage from "../features/entry-exit/pages/GateSelectionPage";
import VehicleTypeSelectionPage from "../features/entry-exit/pages/VehicleTypeSelectionPage";
import UserHomePage from "../features/home/UserHomePage";
import LandingPage from "../features/main/LandingPage";
import Reports from "../features/reports/Reports";
import RequireAuth from "./RequireAuth";
import HomePage from "../features/home/HomePage";
import FloorPage from "../features/masters/pages/FloorPage";
import BlockMasterPage from "../features/masters/pages/BlockMasterPage";
import SlotPage from "../features/masters/pages/SlotPage";
import Attendence from "../features/reports/pages/Attendence";
import Collections from "../features/reports/pages/Collections";
import FeeMasterPage from "../features/masters/pages/FeeMasterPage";
import VehiclePage from "../features/masters/pages/VehiclePage";
import ParkingType from "../features/masters/pages/ParkingType";
import PaymentModePage from "../features/masters/pages/PaymentModePage";
import TransactionsPage from "../features/reports/pages/TransactionsPage";
import DateDetailsTable from "../features/reports/components/DateDetailsTable";
import GateMasterPage from "../features/masters/pages/GateMasterPage";
import StaffOnBoard from "../features/masters/pages/StaffOnBoard";
import ExitBoothMasterPage from "../features/masters/pages/ExitBoothMasterPage";
import ShiftMasterPage from "../features/masters/pages/ShiftMasterPage";
import MainLayout from "../containers/MainLayout";
import ShiftTypePage from "../features/masters/pages/ShiftTypePage";
import CashCollectReportPage from "../features/reports/pages/CashCollectReportPage";
import LocationMaster from "../features/masters/pages/LocationMaster";
import ShiftPage from "../features/masters/pages/ShiftMaster/ShiftPage";
import ShiftAssignPage from "../features/masters/pages/ShiftMaster/ShiftAssignPage";
import ShiftHistoryPage from "../features/masters/pages/ShiftMaster/ShiftHistoryPage";
import StaffOnboardPage from "../features/masters/pages/StaffManagement/StaffOnboardPage";
import Test from "../containers/components/Nav/Test";
import CashCollectDetailReportPage from "../features/reports/pages/CashCollectDetailReportPage";
import RefundReports from "../features/home/components/RefundReports";
import ExtraChargesMasterPage from "../features/masters/pages/ExtraChargesMasterPage";
import ChangeLogoInDashbaordPage from "../features/settings/pages/ChangeLogoInDashbaordPage";
import AvailableSlotsForCars from "../features/entry-exit/pages/AvailableSlots/AvailableSlotsForCars";
import AvailableSlotsForBikes from "../features/entry-exit/pages/AvailableSlots/AvailableSlotsForBikes";
import SettlementReport from "./../features/reports/pages/SettlementReport";
import ComplimentaryDetails from "../features/reports/pages/ComplimentaryDetails";
import ParkingTypeReport from "../features/reports/pages/ParkingTypeReport";

const Routes = () => {
  const { state } = useLocation();
  return (
    <RouterRoutes>
      <Route path="/" element={<LandingPage />} />
      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <HomePage />
          </RequireAuth>
        }
      />

      <Route
        path="/transaction"
        element={
          <RequireAuth>
            <TransactionsPage />
          </RequireAuth>
        }
      />

      <Route 
        path="/parking-type"
        element={
          <RequireAuth>
            <ParkingTypeReport/>
          </RequireAuth>
        }

      /> 

     
      <Route
        path="/transaction/view"
        element={
          <RequireAuth>
            <DateDetailsTable />
          </RequireAuth>
        }
      />
      <Route
        path="/home"
        element={
          <RequireAuth>
            <UserHeader>
              <UserHomePage />
            </UserHeader>
          </RequireAuth>
        }
      />
      <Route
        path="/home/:gateName"
        element={
          <RequireAuth>
            <UserHeader>
              <GateSelectionPage />
            </UserHeader>
          </RequireAuth>
        }
      />
      <Route
        path="/home/:gateName/:gateId"
        element={
          <RequireAuth>
            <UserHeader>
              <VehicleTypeSelectionPage />
            </UserHeader>
          </RequireAuth>
        }
      />
      <Route
        path="/home/entry-gate/:gateId/vehicle/:vehicleId"
        element={
          <RequireAuth>
            <UserHeader>
              <EntryDetailsPage />
            </UserHeader>
          </RequireAuth>
        }
      />
      <Route
        path="/home/exit-gate/:gateId/vehicle/:vehicleId"
        element={
          <RequireAuth>
            <UserHeader>
              <ExitDetailsPage />
            </UserHeader>
          </RequireAuth>
        }
      />

      <Route
        path="/shift-master"
        element={
          <RequireAuth>
            <MainLayout>
              <ShiftMasterPage />
            </MainLayout>
          </RequireAuth>
        }
      />

      <Route
        path="/shift-type"
        element={
          <RequireAuth>
            <MainLayout>
              <ShiftTypePage />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/shiftmaster/test"
        element={
          <RequireAuth>
            <MainLayout>
              <Test />
            </MainLayout>
          </RequireAuth>
        }
      />

      <Route
        path="/entry"
        element={
          <RequireAuth>
            <EntryDetailsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/exit"
        element={
          <RequireAuth>
            <ExitDetailsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/reports"
        element={
          <RequireAuth>
            <Reports />
          </RequireAuth>
        }
      />
      <Route
        path="/floor"
        element={
          <RequireAuth>
            <FloorPage />
          </RequireAuth>
        }
      />
      <Route
        path="/block"
        element={
          <RequireAuth>
            <BlockMasterPage />
          </RequireAuth>
        }
      />
      <Route
        path="/fee"
        element={
          <RequireAuth>
            <FeeMasterPage />
          </RequireAuth>
        }
      />
      <Route
        path="/slot"
        element={
          <RequireAuth>
            <SlotPage />
          </RequireAuth>
        }
      />

      <Route
        path="/attendence"
        element={
          <RequireAuth>
            <Attendence />
          </RequireAuth>
        }
      />
      <Route
        path="/collections"
        element={
          <RequireAuth>
            <Collections />
          </RequireAuth>
        }
      />
      <Route
        path="/vehicle"
        element={
          <RequireAuth>
            <VehiclePage />
          </RequireAuth>
        }
      />
      <Route
        path="/parkingtype"
        element={
          <RequireAuth>
            <ParkingType />
          </RequireAuth>
        }
      />
      <Route
        path="/paymentmode"
        element={
          <RequireAuth>
            <PaymentModePage />
          </RequireAuth>
        }
      />
      <Route
        path="/entrybooth"
        element={
          <RequireAuth>
            <GateMasterPage />
          </RequireAuth>
        }
      />
      <Route
        path="/staffonboard"
        element={
          <RequireAuth>
            <StaffOnBoard />
          </RequireAuth>
        }
      />
      <Route
        path="/exitbooth"
        element={
          <RequireAuth>
            <ExitBoothMasterPage />
          </RequireAuth>
        }
      />
      <Route
        path="/cashcollect"
        element={
          <RequireAuth>
            <CashCollectReportPage />
          </RequireAuth>
        }
      />
      <Route
        path="/location-master"
        element={
          <RequireAuth>
            <LocationMaster />
          </RequireAuth>
        }
      />
      <Route
        path="/shiftmaster"
        element={
          <RequireAuth>
            <ShiftPage />
          </RequireAuth>
        }
      />
      <Route
        path="/shiftmaster/history"
        element={
          <RequireAuth>
            <ShiftHistoryPage />
          </RequireAuth>
        }
      />
      <Route
        path="/shiftmaster/assign"
        element={
          <RequireAuth>
            <ShiftAssignPage />
          </RequireAuth>
        }
      />
      <Route
        path="/staffonboarding"
        element={
          <RequireAuth>
            <StaffOnboardPage />
          </RequireAuth>
        }
      />
      <Route
        path="/cashcollect/view/:id"
        element={
          <RequireAuth>
            <CashCollectDetailReportPage />
          </RequireAuth>
        }
      />
      <Route
        path={
          state?.vehicle === "car_dsh"
            ? "/cars-refund-report/:id"
            : "/bikes-refund-report/:id"
        }
        element={
          // <RequireAuth>
          <RefundReports />
          // </RequireAuth>
        }
      />
      <Route
        path="/extra-charges"
        element={
          <RequireAuth>
            <ExtraChargesMasterPage />
          </RequireAuth>
        }
      />
      <Route
        path="/profile-settings"
        element={
          <RequireAuth>
            <ChangeLogoInDashbaordPage />
          </RequireAuth>
        }
      />
      <Route path="/cars" element={<AvailableSlotsForCars />} />
      <Route path="/bikes" element={<AvailableSlotsForBikes />} />
      <Route
        path="/settlement-report"
        element={
          <RequireAuth>
            <MainLayout>
              <SettlementReport />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/complimentary-details"
        element={
          // <RequireAuth>
          //   <ComplimentaryDetails />
          // </RequireAuth>
          <ComplimentaryDetails />
        }
      />
    </RouterRoutes>
  );
};

export default Routes;
