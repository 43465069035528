import { 
  Box,
  Button, 
  Card,
  FormControl,
  InputLabel, 
  MenuItem, 
  Paper, 
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, 
  TableRow,
  TextField, 
  Typography 
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MainLayout from "../../../containers/MainLayout";
import { fetchParkingTypeDetails } from "../../../api/parkingReport";
import { fetchAllParkingTypes } from "../../../api/parkingTypes";

function ParkingTypeReport() {
  const [parkingType, setParkingType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [parkingTypes, setParkingTypes] = useState([]);

  const tenantId = localStorage.getItem("tenantId");
  const locationId = localStorage.getItem("locationId");

  useEffect(() => {
    const getParkingType = async () => {
      try {
        const response = await fetchAllParkingTypes(locationId, tenantId);
        // Add "All" option to the parking types
        setParkingTypes([{ id: "all", name: "All" }, ...response]);
      } catch (err) {
        console.error("Failed to fetch parking types:", err);
      }
    };

    getParkingType();
  }, [locationId, tenantId]); // Add locationId and tenantId as dependencies

  const handleSearch = async () => {
    setLoading(true);
    setError(null);

    try {
      let response;
      if (parkingType === "all") {
        // Fetch data for all parking types
        response = await fetchParkingTypeDetails(locationId, tenantId, fromDate, toDate, "");
      } else {
        // Fetch data for the selected parking type
        response = await fetchParkingTypeDetails(locationId, tenantId, fromDate, toDate, parkingType);
      }
      setReportData(response?.parkingData || response?.data || []);
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const grandTotalCount = reportData.reduce((sum, row) => sum + (row.totalCount || 0), 0);
  const grandTotalAmount = reportData.reduce((sum, row) => sum + (row.totalAmount || 0), 0);

  return (
    <MainLayout>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Reports / </span>
        </Link>
        Parking Type
      </Typography>

      <Box
        sx={{
          background: "white",
          borderRadius: "10px",
          boxShadow: "0px 3.75px 7.5px rgba(0, 0, 0, 0.12)",
        }}
        className="flex items-center mb-3 p-5"
        gap={2}
      >
        <Box className="flex flex-wrap gap-2 items-center">
          <div>
            <TextField 
              required 
              name="fromDate" 
              type="date" 
              label="From Date" 
              size="small" 
              value={fromDate} 
              onChange={(e) => setFromDate(e.target.value)}
              focused 
            />
          </div>
          <div>
            <TextField 
              required 
              name="toDate" 
              type="date" 
              label="To Date" 
              size="small" 
              value={toDate} 
              onChange={(e) => setToDate(e.target.value)}
              focused 
            />
          </div>
          <div>
            <FormControl size="small" variant="outlined">
              <InputLabel htmlFor="parking-type">Parking Type</InputLabel>
              <Select
                required
                labelId="parking-type"
                id="parking-type"
                label="Parking Type*"
                className="!min-w-[300px]"
                name="parkingTypeId"
                value={parkingType}
                onChange={(e) => setParkingType(e.target.value)}
              >
                {parkingTypes?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          
          <Button
            name="search"
            sx={{
              background: "#576782",
              color: "white",
              "&:hover": { background: "#3e4859" },
            }}
            variant="contained"
            size="small"
            onClick={handleSearch}
            disabled={loading} 
          >
            {loading ? "Loading..." : "Search"} 
          </Button>
        </Box>
      </Box>

      {error && <Typography color="error">{error}</Typography>}

      {reportData.length > 0 && (
        <Card>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#576782" }}>
                  <TableCell sx={{ color: "white" }}>No</TableCell>
                  <TableCell sx={{ color: "white" }}>Date</TableCell>
                  <TableCell sx={{ color: "white" }}>Parking Type</TableCell>
                  <TableCell sx={{ color: "white" }}>Vehicle Type</TableCell>
                  <TableCell sx={{ color: "white" }}>Count</TableCell>
                  <TableCell sx={{ color: "white" }}>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportData.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell>{row.parkingTypename}</TableCell>
                    <TableCell>{row.vehicleTypename}</TableCell>
                    <TableCell>{row.totalCount}</TableCell>
                    <TableCell>{row.totalAmount}</TableCell>
                  </TableRow>
                ))}
                <TableRow sx={{ backgroundColor: "#f0f0f0" }}>
                  <TableCell colSpan={4} sx={{ fontWeight: "bold" }}>Grand Total</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>{grandTotalCount}</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>{grandTotalAmount}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      )}
    </MainLayout>
  );
}

export default ParkingTypeReport;