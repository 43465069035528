import { GET_PARKING_TYPE_REPORT } from "../services/apiUrls";
import { get } from "../services/service";
export const fetchParkingTypeDetails = async (locationId, tenantId,fromDate,toDate,parkingType) => {
  const url = GET_PARKING_TYPE_REPORT.replace("{tenantId}", tenantId)
  .replace("{locationId}",locationId)
  .replace("{fromDate}",fromDate)
  .replace("{toDate}",toDate)
  .replace("{parkingType}",parkingType);
  const response = await get(url); 
  return response;
};
